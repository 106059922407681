import Header from "../Header/Header";
import FooterComponent from "../sections/footer-comp/FooterComponent";

export default function Aboutus() {
    return (
        <div className="aboutus-page">
            <Header />
            <div className="choose2-sec sec-padding container">
                <strong>our popular services</strong><br/>
                Most of the Indian tourists prefer travelling to their preferred destination via road.
                It is due to the fact that a road trip allows you to enjoy the scenic beauty at your own pace.
                Moreover, the comfort of travelling in a vehicle with your family and friends is unparalleled. You can opt for online cab booking that will make your journey hassle-free.
            </div>
            <FooterComponent />
        </div>
    )
}