import Header from "../Header/Header";
import Bannersec from '../sections/banner/banner-sec';
import ChooseUs from '../sections/choose-us/chooseUs';
import ServicesTemplate from '../sections/services/services';
import GetstartTemplate from '../sections/getstarts/getstarts';
import FooterComponent from '../sections/footer-comp/FooterComponent';

export default function Home() {
    return (
        <div className="home-page">
            <Header />
            <Bannersec />
            <ChooseUs />
            <ServicesTemplate />
            <GetstartTemplate />
            <FooterComponent />
        </div>
    )
}