import './policy.css'

export default function PrivacyPolicy() {
    return (
        <div className="privacy-policy-page">
            <div class="container-fluid mt15 n">
                <div class="row">
                    <div class="col-12 bg-black p0 text-center">
                        <img src="/images/terms.jpg?v=0.1" alt="Terms and Conditions" title="Terms and Conditions" class="img-fluid" />
                    </div>
                </div>
            </div>
            <div class="row bg-gray">
                <div class="col-12">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 mb20 mt30 ul-style-c">
                                <div class="bg-white-box">
                                    <h2 class="font-18 mt30"><b><u>PRIVACY AND COPYRIGHT PROTECTION</u></b></h2>
                                    <p>IT Cab's Privacy Policies explain how we treat your personal data and protect your privacy when you use our Services. By using our Services, you agree that IT Cab can use such data in accordance with our Privacy Policies.</p>
                                    <p>Content displayed on our website is copyrighted by IT Cab Cabs. We respect all copyrights and trademarks owned by third-parties. Any third-party content or logos displayed on this website are owned by the respective third-party. We expect you to respect all copyrights. You may not copy or reproduce any content offered by IT Cab Cabs Services without express consent of IT Cab Cabs.</p>
                                    <h2 class="font-18 mt30"><b><u>REQUIREMENTS AND CONDUCT</u></b></h2>
                                    <p>The Service is not available for use by persons under the age of 18. You may not authorize third parties to use your Account, and you may not allow persons under the age of 18 to receive services from Third Party Providers unless they are accompanied by you. You may not assign or otherwise transfer your Account to any other person or entity.</p>
                                    <p>You agree to comply with all applicable laws when using the Services, and you may only use the Services for lawful purposes.</p>
                                    <p>By use of our services you undertake the responsibility to ensure that the service meets your needs and expectations. We provide the service ‘as-is' and do not make any commitment to the content, nature, reliability or safety of the service.</p>
                                    <p>You will not in your use of the Services cause nuisance, annoyance, inconvenience, personal or property damage, whether to the Third Party Provider or any other party.</p>
                                    <p>Customer agrees to raise complaints about service, if any, within 7days of completion of service. Our ability to address grievances shall be severely limited and only at a best effort basis, after 7days of completion of the trip.</p>
                                    <p>In certain instances, IT Cab Cabs and/or partner providers may require you to provide proof of identity to access or use the services, and you agree that you may be denied access or use of the services if you refuse to provide proof of identity. No cancellation refund (100% amount of the booking shall be considered as due by customer) shall be issued if we deny service due to refusal to provide proof of identity.</p>
                                    <h2 class="font-18 mt30"><b><u>MODIFYING AND TERMINATING OUR SERVICES</u></b></h2>
                                    <p>We are constantly changing and improving our Services. We may add or remove functionalities or features and we may suspend or stop some part of or an entire service altogether.</p>
                                    <p>At most times, we include specific rules, terms, conditions and requirements that apply to the product or service that you use on our platform. You are expected to read, understand and comply with those terms. By using our services, you accept those terms and agree to comply with them.</p>
                                    <p>You can stop using our Services at any time, although we would be sorry to see you go. IT Cab may also stop providing Services to you or add or create new limits to our Services at any time.</p>
                                    <p>We believe that you own your data, and preserving your access to such data is important. If we discontinue a Service, where reasonably possible, we will give you reasonable advance notice and a chance to remove information from that Service.</p>
                                    <h2 class="font-18 mt30"><b><u>OUR LIST PRICING AND PROMOTIONS</u></b></h2>
                                    <p> Pricing for items (products or services) sold on the platform may be subject to change at anytime without prior notice. </p>
                                    <p> With respect to items (products or services) sold on the platform, we cannot confirm the price of an item until you order. Despite our best efforts, a small number of the items may be mispriced or unavailable for delivery at your requested time. If the correct price of an item sold on the platform is higher than our stated selling price or if the item is unavailable for any reason, we will, at our discretion, either contact you for instructions before delivering on your order or cancel your order and notify you of such cancellation. We will waive applicable cancellation fees for such cancellations.</p>
                                    <p>Prices for items (products or services) that are ordered with assistance from a team member may be priced slightly higher than the prices on self-service platforms (website or mobile apps). These support facility charges are included in your order price to cover the costs of the support facilities &amp; personnel involved in enabling such orders. Upon cancellation of such (assisted) orders, applicable support facility charges shall not be refunded. These costs are already incurred by us as part of providing the support facilities and hence these charges shall be added to the cancellation charges applicable to your order upon its cancellation.</p>
                                    <p>We offer promotions from time to time. No two promotional offers can be combined. Only 1 promotional offer can be applied to any order.</p>
                                    <h2 class="font-18 mt30"><b><u>OUR WARRANTIES AND DISCLAIMERS</u></b></h2>
                                    <p> We provide our Services using a commercially reasonable level of skill and care and we hope that you will enjoy using them. But there are certain things that we do not promise about our Services.</p>
                                    <p> OTHER THAN AS EXPRESSLY SET OUT IN THESE TERMS OR ADDITIONAL TERMS, NEITHER IT Cab NOR ITS PROVIDERS, SUPPLIERS, DISTRIBUTORS OR AFFILIATES MAKES ANY SPECIFIC PROMISES ABOUT THE SERVICES. FOR EXAMPLE, WE DO NOT MAKE ANY COMMITMENTS ABOUT THE CONTENT WITHIN THE SERVICES, THE SPECIFIC FUNCTIONS OF THE SERVICES OR THEIR SAFETY, RELIABILITY, AVAILABILITY OR ABILITY TO MEET YOUR NEEDS. WE PROVIDE THE SERVICES "AS IS".</p>
                                    <p> THE SERVICES MADE AVAILABLE ON OUR PLATFORM ARE DELIVERED BY INDEPENDENT THIRD-PARTIES. IT IS YOUR RESPONSIBILTIY TO ENSURE THAT THE PROVIDERS’ SERVICE MEETS YOUR NEEDS. IT Cab IS ONLY PROVIDING A MARKETPLACE PLATFORM FOR SERVICES AND ASSUMES NO RESPONSIBILITY OR LIABILITY FOR THE ACTIONS OR OMISSIONS OF THE THIRD PARTY PROVIDER INCLUDING NON-ADHERENCE OF THE SCHEDULED TIMINGS, BEHAVIOUR OF THEIR STAFF, CONDITIONS INSIDE THE CAB, LOSS OF LIFE OR PROPERTY, DELAY, BREAKDOWN OR INCONVENIENCE SUFFERED BY ANY CONSUMER OF THE SERVICE.</p>
                                    <p> SOME JURISDICTIONS PROVIDE FOR CERTAIN WARRANTIES, LIKE THE IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. TO THE EXTENT PERMITTED BY LAW, WE EXCLUDE ALL WARRANTIES.</p>
                                    <p> WE REQUEST THAT YOU USE YOUR JUDGEMENT WHEN USING OUR SERVICES AND WE SUGGEST THAT YOU DO NOT USE THE SERVICE IF IT DOES NOT MEET YOUR EXPECTATIONS. ALWAYS ENSURE BEFORE YOU RIDE THAT THE VEHICLE AND DRIVER MATCH THE IDENTIFICATION (DRIVER LICENSE, DRIVER NAME AND VEHICLE LICENSE PLATE) THAT WAS PROVIDED. IT Cab TAKES APPROPRIATE MEASURES TO ENSURE THAT OUR PARTNERS, THEIR ASSOCIATED DRIVERS AND CARS ARE COMPLIANT WITH LOCAL LAWS. WHILE WE EXERCISE DUE DILIGENCE ON OUR PART, IT IS THE CONSUMERS RESPONSIBILITY TO MATCH THE VEHICLE AND DRIVER WITH THE INFORMATION PROVIDED BY IT Cab BEFORE THEY RIDE. IF A CONSUMER DECIDES TO RIDE WITH A DRIVER OR IN A VEHICLE DIFFERENT THAT WAS DESIGNATED BY IT Cab, THEY SHALL BE SOLELY RESPONSIBLE FOR THE CONSEQUENCES.</p>
                                    <p> FOR ALL PURPOSES, WE DO NOT ASSUME RESPONSIBILITY FOR ANY HARM, DAMAGE OR LOSS TO YOUR PERSON OR PROPERTY THAT MAY RESULT FROM SUCH ACTIONS OR INACTIONS RELATING TO YOUR ORDER ON THE IT Cab PLATFORM. THIS INCLUDES AND IS NOT LIMITED TO MISSED TRAVEL ARRANGEMENTS. TRIP DELAYS THAT TAKE LONGER THAN ORGINALLY ESTIMATED, CONSEQUENCES OF ROAD ACCIDENTS OR ANY OTHER EVENTS THAT LEAD TO UNPLANNED OR UNDESIRABLE CONSEQUENCES INCLUDING MISSED CONNECTIONS TO FLIGHTS, TRAINS, MISSED APPOINTMENTS, MEETINGS OR IMPORTANT EVENTS OR ANY PHYSICAL DAMAGE TO PERSON OR PROPERTY.</p>
                                    <p> TO THE EXTENT PERMITTED BY LAW, THE TOTAL LIABILITY OF IT Cab, AND ITS SUPPLIERS AND PARTNERS, FOR ANY CLAIMS UNDER THESE TERMS, INCLUDING FOR ANY IMPLIED WARRANTIES, IS LIMITED TO THE ACTUAL AMOUNT YOU PAID US TO USE OUR SERVICES. WE DO NOT ASSUME LIABILITY FOR ANY DAMAGE CAUSED, WHETHER INCIDENTAL, CONSEQUENTIAL OR DIRECT. YOU AGREE THAT OUR LIABILITY IN ANY EVENT IS LIMITED TO THE AMOUNT YOU PAID US TO USE OUR SERVICES OR ₹10,000 WHICHEVER IS LOWER.</p>
                                    <h2 class="font-18 mt30"><b><u>CANCELLATION &amp; REFUND POLICY</u></b></h2>
                                    <p><b>Cancellation Policy </b></p>
                                    <p>We have designed our cancellation policy to enable customers to have the most flexibility and convenience in travel. You may cancel your booking with us any time before your scheduled date and time of pickup. This may be done using the IT Cab Mobile App / Website by logging with your registered credentials or by calling us anytime on the 24x7 Customer Care Helpline +91 90518-77-000.</p>
                                    <p>In your booking confirmation, we indicate that your booking may be cancelled for Free (no cancellation charges apply) if you cancel before a certain date and time. This signifies your “Free Cancellation period”. If you choose to cancel a booking, please do so before the end of your “Free Cancellation period”. Once your “Free cancellation period” has ended, cancellation charges shall be applicable for the booking.</p>
                                    <p>Depending on when you cancel your booking after the end of your “Free cancellation period”, your cancellation charges may vary. Our cancellation charges enable us to compensate our taxi operators for their incurred and opportunity costs of holding the vehicle reservation in place for your convenience.</p>
                                    <ul>
                                        <li><i class="fas fa-check-circle mr10 color-green"></i>We try to provide you vehicle and driver information as quickly as the vehicle &amp; driver is allocated to your reservation. However, we request that you only access car &amp; driver information that is made available to you if you are absolutely certain of your travel plans. The “Free cancellation period” for your booking shall end as soon as you have accessed car &amp; driver information on our platform. We do provide you fair warning when you try to access this information, however you agree that you shall be liable for applicable cancellation charges if you cancel the booking after accessing car &amp; driver information for your booking.</li>
                                        <li><i class="fas fa-check-circle mr10 color-green"></i>We provide you complete transparency the applicable cancellation charges and how the applicable cancellation charges vary in the time windows from the time you create your booking order until the start time for your trip. You agree to be be liable to pay the the applicable Cancellation Charges for your booking order in accordance with the details mentioned in your booking order confirmation.</li>
                                        <li><i class="fas fa-check-circle mr10 color-green"></i>If you cancel your booking and if we owe you any balance amount after we deduct all applicable cancellation charges, then such balance amount, if any, shall be credited to your IT Cab account within 8 working days. If you have a cash amount balance in your IT Cab account, you may request for it to be transferred to your bank account or use it to create a new booking at any time.</li>
                                        <li><i class="fas fa-check-circle mr10 color-green"></i>If you utilized our support facilities for the creation of your booking order, we have included the cost of providing such support as part of your booking order. Upon cancellation of such a booking order, the support facility charges shall not be refunded and instead will be retained by us in addition to any cancellation charges that apply to your booking order.</li>
                                    </ul>
                                    <p>IT Cab Cabs strives to have a 100% Fulfilment score. We hope to provide you a car 100% of the times when you book with us. If we are unable to provide you with a vehicle for any reason whatsoever, we shall not only refund your money but as a courtesy we will also credit you the same count of IT Cab Coins (no cash value, to be used as future booking credits only) as the booking amount of the booking we were unable to deliver. You may redeem IT Cab coins in part or full towards any other IT Cab Booking(s) in future. Usage of IT Cab Coins shall be subject to IT Cab coins <a href="javascript:void(0);" onclick="showTcIT CabCoins()">terms and conditions</a> as laid down on our website www.IT Cab.com. A Customer may login to www.IT Cab.com, using his login credentials to check his IT Cab Coins Balance.</p>
                                    <p>If for any reason your driver takes too long to get to you then the cancellation charge will be waived in the event of a cancellation request made at your end.</p>
                                    <p>If there is a “Customer No-Show for service” event, i.e. you are not at the pickup location and/or your assigned Driver is unable to get in touch with you on your registered phone number at the scheduled pickup date and time, the Driver shall wait for a reasonable period of half an hour from the scheduled Booking time and thereafter we will treat the Booking as cancelled. As stated hereinabove, You shall be liable to pay the entire Booking Amount as Cancellation Charges for such a booking.</p>
                                    <p>The onus shall be on the Customer to inform IT Cab Cabs at least 24 hours before the time of departure, for any delays or rescheduling of departure time. We may not be able to change your departure time as this subject to the availability of the car at such rescheduled time.</p>
                                    <p>Flash sale booking can be cancelled within 1 hr of confirmation by IT Cab</p>
                                    <h2 class="font-18 mt30 text-uppercase"><b><u>Refund Policy</u></b></h2>
                                    <ul>
                                        <li><i class="fas fa-check-circle mr10 color-green"></i>In the event of cancellation of your booking, we shall be issuing you a refund of the entire amount paid to us in advance for the given booking less applicable cancellation charges and any applicable support facility charges. To request a refund, simply contact us with your booking details within thirty (30) days of your purchase. Please include your Booking ID (sent to you via sms and email after booking). Optionally, please tell us why you're requesting a refund – we take customer feedback very seriously and use it to constantly improve our offering and quality of service. Refunds are usually processed within 21 days from the date of request and is limited to the amount paid to us in advance for the given booking.</li>
                                        <li><i class="fas fa-check-circle mr10 color-green"></i>Refunds are subject to Cancellation charges, if applicable (for details please refer to our Cancellation Policy above).</li>

                                    </ul>
                                    <h2 class="font-18 mt30 text-uppercase"><b><u>FORCE MAJEURE</u></b></h2>
                                    <p>IT Cab shall not be liable or responsible for its failure to perform or delay in performance of the service obligations if such failure or delay is attributable to or arises out of any Force Majeure Event.</p>
                                    <p><b>"Force Majeure Event"</b> means an event that is not within the reasonable control of the Party whose performance is affected thereby and shall include any of the following events:</p>
                                    <ul>
                                        <li><i class="fas fa-check-circle mr10 color-green"></i>acts of God or elements (such as, but not limited to, fire, explosions, drought, tidal waves, flood, frost, earthquake, storm, lightning, hurricane, landslide, tornado, epidemic or pandemic),</li>
                                        <li><i class="fas fa-check-circle mr10 color-green"></i>war, hostilities (whether war be declared or not), invasion, act of foreign enemies, mobilisation, requisition, or embargo;</li>
                                        <li><i class="fas fa-check-circle mr10 color-green"></i>rebellion, revolution, insurrection, violent demonstrations, sabotage, or military or usurped power, or civil war;</li>
                                        <li><i class="fas fa-check-circle mr10 color-green"></i>riot, commotion, strikes, go slows, lock outs or disorder, breach of peace, civil unrest, labour unrest,</li>
                                        <li><i class="fas fa-check-circle mr10 color-green"></i>acts or threats of terrorism.</li>
                                        <li><i class="fas fa-check-circle mr10 color-green"></i>action of statutory authorities, unions, local or central governments, civil or military authorities, </li>
                                        <li><i class="fas fa-check-circle mr10 color-green"></i>telecommunication failure, quarantine or due to any other unforeseeable circumstances beyond the control of IT Cab. </li>
                                    </ul>
                                    <p>For the duration of a Force Majeure event, the service obligations of IT Cab will be put on hold. Once the event has ended, the service may recommence. IT Cab shall not be responsible for disruption or cancellation of customer plans due to cancellation or delay in services by IT Cab due to reasons attributable to Force Majeure Events. Our service levels may be degraded while such Force Majeure events shall in effect and we shall in earnest, try to make you aware of how it affects our service.</p>
                                    <h2 class="font-18 mt30 text-uppercase"><b><u>SITE POLICIES, MODIFICATIONS AND SEVERABILITY</u></b></h2>
                                    <p>Please review our other policies, such as our disclaimer &amp; privacy policy, posted on this site. These policies also govern your use of the IT Cab Platform.</p>
                                    <p>We reserve the right to make changes to our site, policies, Service Terms, and these Conditions of Use at any time. If any of these conditions shall be deemed invalid, void, or for any reason unenforceable, that condition shall be deemed severable and shall not affect the validity and enforceability of any remaining condition</p>
                                    <h2 class="font-18 mt30 text-uppercase"><b><u>ABOUT THESE TERMS</u></b></h2>
                                    <p>We may modify these terms or any additional terms that apply to a Service to, for example, reflect changes to the law or changes to our Services. You should look at the terms regularly. We'll post notice of modifications to these terms on this page. We'll post notice of modified additional terms in the applicable Service. Changes will not apply retrospectively and will become effective the day after they are posted. However, changes addressing new functions for a Service or changes made for legal reasons will be effective immediately. If you do not agree to the modified terms for Service, you should discontinue your use of our Service.</p>
                                    <p>If there is any inconsistency between these terms and any of the additional terms, the additional terms will prevail to the extent of the inconsistency.</p>
                                    <p>These terms govern the relationship between IT Cab and you. They do not create any third party beneficiary rights.</p>
                                    <p>If you do not comply with these terms and we do not take action immediately, this doesn't mean that we are giving up any rights that we may have (such as taking action in the future).</p>
                                    <p>If it turns out that a particular term is not enforceable, this will not affect any other terms.</p>
                                    <p>The laws of India will apply to any disputes arising out of or relating to these terms or the Services. All claims arising out of or relating to these terms or the Services will be referred to an arbitrator appointed by IT Cab, failing him to any other arbitrator chosen by IT Cab and you in writing. The decision of such an arbitrator shall be binding on both parties.</p>
                                    <p>For information about how to contact IT Cab, please refer to our website.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}