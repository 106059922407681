// import './chooseus.scss';

const ChooseUs = () => {
    return (
        <section className="choose2-sec sec-padding">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-6 order-xl-1 order-2">
                        <div className="choose-imgs d-sm-flex align-items-start">
                            <img className="img-fluid" src="images/choose2.jpg" alt="IT Cabs" />
                            <img className="img-fluid" src="images/choose1.jpg" alt="IT Cabs" />
                        </div>
                    </div>
                    <div className="col-xl-6 order-xl-2 order-1">
                        <div className="choose-txt ps-xl-5 ms-xl-3">
                            <div className="sec-intro">
                                <h2 className="sec-title">Why <span className="highlighted">choose us?</span></h2>
                                <p>our expert staff offer a high quality service at a lower price than our competitors. That’s because we’re a dedicated rental service focusing solely on the Gatwick area and we understand that after a long flight, you want to be on your way without delay.</p>
                            </div>
                            <div className="car-feature mt-5">
                                <ul>
                                    <li><span>expert guidance</span></li>
                                    <li><span>free customer support</span></li>
                                    <li><span>affordable prices</span></li>
                                    <li><span>easy &amp; fast booking</span></li>
                                    <li><span>expert guidance</span></li>
                                    <li><span>free customer support</span></li>
                                    <li><span>many pickup location</span></li>
                                    <li><span>no more waiting</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ChooseUs;