const ServicesTemplate = () => {
    return (
        <section className="services2 sec-padding" style={{ backgroundImage: "url('images/serv-bg3.jpg')" }}>
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-sm-9">
                        <div className="sec-intro">
                            <h2 className="sec-title">our popular services</h2>
                            <p>Most of the Indian tourists prefer travelling to their preferred destination via road. It is due to the fact that a road trip allows you to enjoy the scenic beauty at your own pace. Moreover, the comfort of travelling in a vehicle with your family and friends is unparalleled. You can opt for online cab booking that will make your journey hassle-free.</p>
                        </div>
                    </div>
                    <div className="col-lg-5 offset-lg-1">
                        <div className="d-flex justify-content-between">
                            <div className="sec-graphic">
                                <img src="images/arrow-gold.png" alt="IT Cabs" />
                            </div>
                            <div className="sec-cta">
                                <a href="#" className="btn btn-primary">Get a Service <i className="fa fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="service-lists mt-5">
                            <div className="service-entry d-sm-flex justify-content-between align-items-center">
                                <div className="serv-icon"><img src="images/icons/serv1.png" alt="IT Cabs" /></div>
                                <div className="serv-txt">
                                    <h3><a href="#">Out Station</a></h3>
                                    <p>Outstation car rentals are a good option for travellers who are planning a long-distance road trip or who need to travel between two different cities for business or leisure.</p>
                                </div>
                                <a href="#" className="btn btn-dark">Book Now <i className="fa fa-arrow-right ms-2"></i></a>
                                <div className="serv-img rounded">
                                    <a href="#"><img src="images/serv1.png" alt="IT Cabs" /></a>
                                </div>
                            </div>
                            <div className="service-entry d-sm-flex justify-content-between align-items-center">
                                <div className="serv-icon"><img src="images/icons/serv3.png" alt="IT Cabs" /></div>
                                <div className="serv-txt">
                                    <h3><a href="#">Taxi Reserved</a></h3>
                                    <p>Arrange for it to be kept specially for you.</p>
                                </div>
                                <a href="#" className="btn btn-dark">Book Now <i className="fa fa-arrow-right ms-2"></i></a>
                                <div className="serv-img rounded">
                                    <a href="#"><img src="images/serv3.png" alt="IT Cabs" /></a>
                                </div>
                            </div>
                            <div className="service-entry d-sm-flex justify-content-between align-items-center">
                                <div className="serv-icon"><img src="images/icons/intercity.png" alt="IT Cabs" /></div>
                                <div className="serv-txt">
                                    <h3><a href="#">intercity service</a></h3>
                                    <p>Your Reliable Ride, Anytime, Anywhere.</p>
                                </div>
                                <a href="#" className="btn btn-dark">Book Now <i className="fa fa-arrow-right ms-2"></i></a>
                                <div className="serv-img rounded">
                                    <a href="#"><img src="images/serv4.png" alt="IT Cabs" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesTemplate;