import { Card } from "react-bootstrap";
import './TripFinder.scss';
import { configPath } from "../../constants/constant";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useRef, useState } from "react";
import { Loader } from "@googlemaps/js-api-loader"
import apiHandler from "../../services/apiHandler";
import { isEmpty } from "lodash";
import Swal from "sweetalert2";
import Spinner from "../loader/Loader";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import setSeconds from "date-fns/setSeconds";
import moment from "moment";


process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

let placesApiClient

async function getGoogleMapsPlacesApiClient() {
    if (placesApiClient) {
        return placesApiClient
    }
    const loader = new Loader({
        apiKey: configPath.google_api_key || "",
        version: "weekly",
        region: 'in'
    })
    placesApiClient = await loader.importLibrary("places")
    return placesApiClient
}

const TripFinder = ({ setCabList }) => {
    const [tripType, setTripType] = useState('oneway');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [valueFrom, setValueFrom] = useState("");
    const [valueTo, setValueTo] = useState("");
    const [fetchingPredictions, setFetchingPredictions] = useState(false);
    const [suggestionsFrom, setSuggestionsFrom] = useState([]);
    const [suggestionsTo, setSuggestionsTo] = useState([]);
    const [placeDetail, setPlaceDetail] = useState({
        from: {},
        to: {},
    });
    const [isLoading, setIsLoading] = useState(false);

    const sessionTokenRef = useRef()

    const timeoutRef = useRef()
    const handleChange = (event, flag) => {
        const newValue = event.target.value;

        if (flag && flag === 'from') {
            setValueFrom(newValue);
        } else {
            setValueTo(newValue);
        }

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
        }

        if (flag === 'from' && (!newValue || newValue.trim().length <= 3)) {
            setSuggestionsFrom([]);
            return
        }

        if (flag === 'to' && (!newValue || newValue.trim().length <= 3)) {
            setSuggestionsTo([]);
            return
        }

        timeoutRef.current = setTimeout(async () => {
            const places = await getGoogleMapsPlacesApiClient()

            if (!sessionTokenRef.current) {
                sessionTokenRef.current = new places.AutocompleteSessionToken()
            }

            setFetchingPredictions(true)
            // @see https://developers.google.com/maps/documentation/javascript/place-autocomplete
            new places.AutocompleteService().getPlacePredictions(
                {
                    input: newValue,
                    sessionToken: sessionTokenRef.current
                },
                (predictions, status) => {
                    // console.log(predictions, status)
                    setFetchingPredictions(false)
                    if (status === places.PlacesServiceStatus.ZERO_RESULTS && flag === 'from') {
                        setSuggestionsFrom([])
                        return
                    } else if (status === places.PlacesServiceStatus.ZERO_RESULTS && flag === 'to') {
                        setSuggestionsTo([]);
                        return;
                    }

                    if (status !== places.PlacesServiceStatus.OK || !predictions) {
                        alert(status)
                        return
                    }

                    if (flag === 'from') {
                        setSuggestionsFrom(predictions);
                    } else {
                        setSuggestionsTo(predictions);
                    }

                }
            )
        }, 350)
    }

    const handleSuggestionSelected = async (suggestion, flag) => {
        if (flag && flag === 'from') {
            setValueFrom(suggestion.description)
            setSuggestionsFrom([]);
        } else {
            setValueTo(suggestion.description);
            setSuggestionsTo([]);
        }

        const places = await getGoogleMapsPlacesApiClient();
        const sessionToken = sessionTokenRef.current
        sessionTokenRef.current = undefined
        // @see https://developers.google.com/maps/documentation/javascript/places
        new places.PlacesService(
            document.getElementById("googlemaps-attribution-container")
        ).getDetails(
            {
                placeId: suggestion.place_id,
                fields: [
                    // @see https://developers.google.com/maps/documentation/javascript/place-data-fields
                    "formatted_address",
                    "name",
                    "place_id",
                    "geometry.location"
                ],
                sessionToken
            },
            (place, status) => {
                if (status === places.PlacesServiceStatus.OK) {
                    const pcd = { ...placeDetail };
                    pcd[flag] = place;
                    setPlaceDetail(pcd);
                }
            }
        )
    }

    //click on trip type 
    const onTripTypeClick = (evt, type) => {
        setTripType(type);
    };

    const getCabDetails = async (routesInfo) => {

        if (isEmpty(routesInfo)) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
            });
            return;
        }
        const { routes } = routesInfo;
        const routeDetails = routes[0]['legs'][0];
        let distance = Math.ceil((routeDetails.distance.value / 1000));

        const reqData = {
            "cabId": 2,
            "tripType": tripType,
            "oneSideDistance": Number(distance),
            "startTime": moment(startDate).format('YYYY-MM-DDTHH:mm:ss'),
            "endTime": moment(endDate).format('YYYY-MM-DDTHH:mm:ss'),
        };

        setCabList([]);
        const cabData = await apiHandler('post', `${configPath.api_baseUrl}calculate`, reqData);
        if (!isEmpty(cabData)) {
            setCabList(cabData.data);
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong! Please try again",
            });
        }
    };

    const findTrip = async () => {
        if (isEmpty(placeDetail) || isEmpty(placeDetail['from']) || isEmpty(placeDetail['to'])) {
            Swal.fire('Please select source and destination before proceed');
            return;
        }
        setIsLoading(true);
        const directionsService = new window.google.maps.DirectionsService();
        const origin = { lat: placeDetail.from.geometry.location.lat(), lng: placeDetail.from.geometry.location.lng() };
        const destination = { lat: placeDetail.to.geometry.location.lat(), lng: placeDetail.to.geometry.location.lng() };

        directionsService.route(
            {
                origin: origin,
                destination: destination,
                travelMode: window.google.maps.TravelMode.DRIVING
            },
            (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    getCabDetails(result);
                    setIsLoading(false);
                } else {
                    console.error(`error fetching directions ${result}`);
                    setIsLoading(false);
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                    });
                }
            }
        );
    };

    useEffect(() => {
        // getApiCall();
    }, []);


    return (
        <Card>
            <Card.Header className="bokking-title">Book Your Ride</Card.Header>
            <Card.Body>
                <div className="pt-10 mx-0 relative px-20 xl:px-0">
                    <div className="border-none shadow-5 p-20 flex flex-col gap-10 rounded-20 bg-white undefined">
                        <div className="flex justify-between items-center">
                            <div className="flex flex-col">
                                <div role="tablist" className="flex items-center gap-10 justify-center mb-5">
                                    <button type="button" className={`${(tripType === 'oneway') ? 'text-primary  border-secondary' : ''} flex items-center relative transition-all min-h-[40px] border rounded-full px-15 text-selection-outline me-2`}
                                        onClick={evt => onTripTypeClick(evt, 'oneway')}>One Way</button>
                                    <button type="button" className={`${(tripType === 'roundtrip') ? 'text-primary  border-secondary' : ''} flex items-center relative transition-all min-h-[40px] border rounded-full px-15`}
                                        onClick={evt => onTripTypeClick(evt, 'roundtrip')}>Round Trip</button>
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-0.5 cursor-pointer h-[60px]">
                            <div className="relative flex gap-0.5 flex-1">
                                <div className="flex flex-grow items-center relative">
                                    <label className="body-md select-none pointer-events-none absolute transition-all flex origin-[left_center] self-end">From</label>
                                    <input
                                        id="location-input-from"
                                        style={{
                                            fontSize: "1rem",
                                            width: "100%",
                                            maxWidth: "100%",
                                            borderStyle: "solid",
                                            borderWidth: "2px",
                                            borderRadius: "0.25rem",
                                            marginRight: "0.5rem",
                                            padding: "0.5rem"
                                        }}
                                        placeholder="Enter an address or a place name"
                                        onChange={evt => handleChange(evt, 'from')}
                                        value={valueFrom}
                                        autoComplete="off"
                                    />
                                    {fetchingPredictions && <p className="flex border-1 absolute addressDropdown">Fetching predictions...</p>}

                                    {suggestionsFrom.length > 0 && (
                                        <div className="flex border-1 absolute addressDropdown">
                                            <ul style={{ listStyleType: "none", padding: "0" }} role="listbox">
                                                {suggestionsFrom.map(suggestion => (
                                                    <li
                                                        key={suggestion.place_id}
                                                        style={{
                                                            fontSize: "1rem",
                                                            padding: "0.25rem 0.5rem",
                                                            margin: "0.25rem 0",
                                                            backgroundColor: "rgba(255, 255, 255, 0.15)",
                                                            cursor: "pointer"
                                                        }}
                                                        tabIndex={-1}
                                                        role="option"
                                                        aria-selected="false"
                                                        onClick={() => handleSuggestionSelected(suggestion, 'from')}
                                                    >
                                                        {suggestion.description}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                                <div className="flex flex-grow items-center relative">
                                    <label className="body-md select-none pointer-events-none absolute transition-all flex origin-[left_center] self-end">To</label>
                                    <input
                                        id="location-input-to"
                                        style={{
                                            fontSize: "1rem",
                                            width: "100%",
                                            maxWidth: "100%",
                                            borderStyle: "solid",
                                            borderWidth: "2px",
                                            borderRadius: "0.25rem",
                                            padding: "0.5rem"
                                        }}
                                        placeholder="Enter an address or a place name"
                                        onChange={evt => handleChange(evt, 'to')}
                                        value={valueTo}
                                        autoComplete="off"
                                    />
                                    {fetchingPredictions && <p className="flex border-1 absolute addressDropdown">Fetching predictions...</p>}

                                    {suggestionsTo.length > 0 && (
                                        <div className="flex border-1 absolute addressDropdown">
                                            <ul style={{ listStyleType: "none", padding: "0" }} role="listbox">
                                                {suggestionsTo.map(suggestion => (
                                                    <li
                                                        key={suggestion.place_id}
                                                        style={{
                                                            fontSize: "1rem",
                                                            padding: "0.25rem 0.5rem",
                                                            margin: "0.25rem 0",
                                                            backgroundColor: "rgba(255, 255, 255, 0.15)",
                                                            cursor: "pointer"
                                                        }}
                                                        tabIndex={-1}
                                                        role="option"
                                                        aria-selected="false"
                                                        onClick={() => handleSuggestionSelected(suggestion, 'to')}
                                                    >
                                                        {suggestion.description}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-0.5 cursor-pointer h-[60px]">
                            <div className="flex items-center justify-between border-none relative w-[320px] gap-0.5 overflow-visible calendarInput">
                                <div className="bg-charcoal-40 hover:bg-neutral-subtle-over w-full">
                                    <div className="flex justify-between items-center relative w-full h-[60px] justify-center border-b-4 lg:min-h-[60px] border-transparent">
                                        <div className="flex-1 h-full flex flex-col justify-center px-15 py-10 ">
                                            <div className="flex items-center ">
                                                <div className="flex flex-col">
                                                    <p className="body-xs text-neutral-400">Departure</p>
                                                    <DatePicker selected={startDate}
                                                        showTimeInput
                                                        timeFormat="HH:mm:ss"
                                                        dateFormat="dd/MM/YYYY h:mm aa"
                                                        timeInputLabel="Pickup Time:"
                                                        onChange={(date) => setStartDate(date)} className="h6 max-w-[190px] truncate text-primary font-medium font-medium date-picker" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-charcoal-40 border-charcoal-40 hover:bg-neutral-subtle-over hover:border-contrast w-full">
                                    <div className="flex justify-between items-center relative w-full h-[60px] justify-center border-b-4 lg:min-h-[60px] border-transparent">
                                        <div className="flex-1 h-full flex flex-col justify-center px-15 py-10 ">
                                            <div className="flex items-center ">
                                                <div className="flex flex-col">
                                                    <p className="body-xs text-neutral-400">Return</p>
                                                    <DatePicker selected={endDate}
                                                        showTimeInput
                                                        timeFormat="HH:mm:ss"
                                                        dateFormat="dd/MM/YYYY h:mm aa"
                                                        timeInputLabel="Drop Time:"
                                                        onChange={(date) => setEndDate(date)}
                                                        disabled={tripType === 'oneway'}
                                                        className="h6 max-w-[190px] truncate text-primary font-medium font-medium date-picker" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-0.5 cursor-pointer h-[60px]">
                            <div className="relative overflow-visible">
                                <button className="inline-flex justify-center flex-1 items-center bg-brand-solid text-brand-solid hover:bg-brand-solid-over gap-5 rounded-10 min-h-[50px] button-lg py-[13px] px-15 rounded-none rounded-r-10 text-2xl w-[160px] pl-[25px] btn btn-dark" onClick={evt => findTrip()}>
                                    Find a Taxi
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Card.Body >
            <div id="googlemaps-attribution-container"></div>
            {isLoading && < Spinner />}
        </Card >
    )
}

export default TripFinder;