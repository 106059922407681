import logo from './logo.svg';
import './App.css';
import { Routes, Route } from "react-router-dom";
import Home from './component/pages/Home';
import Aboutus from './component/pages/Aboutus';
import Contactus from './component/pages/Contactus';
import PrivacyPolicy from './component/pages/PrivacyPolicy';
import TermPage from './component/pages/TermPage';


function App() {
  return (
    <div className="App container-flud">
      <Routes>
        <Route index element={<Home />} />
        <Route path="about-us" element={<Aboutus />} />
        <Route path="contact-us" element={<Contactus />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="tnc" element={<TermPage />} />
        
        
        {/* <Route path="blogs" element={<Blogs />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} /> */}
      </Routes>
    </div>
  );
}

export default App;
