import CabListPanel from "../../cablist/cablist";
import TripFinder from "../../TripFinder/TripFinder";
import { useState } from 'react';
import { isEmpty } from 'lodash';


const Bannersec = () => {

    const [cabList, setCabList] = useState([]);

    return (
        <section className="banner-sec">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 order-2 order-md-1">
                        <div className="banner-txt">
                            <TripFinder setCabList={setCabList} />
                            <div className="rider-cta bg-info rounded-3 text-center mt-3">
                                <span className="text-xs text-capitalize">Happy rider</span>
                                <ul className="list-unstyled">
                                    <li><img src="images/ri1.png" alt="IT Cabs" /></li>
                                    <li><img src="images/ri2.png" alt="IT Cabs" /></li>
                                    <li><img src="images/ri3.png" alt="IT Cabs" /></li>
                                    <li><a herf="javascript:void(0)"><i className="fa fa-arrow-right"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 order-1 order-md-2">
                        <div className="banner-img mb-5 mb-lg-0">
                            <img src="images/banner2.png" alt="IT Cabs" className="img-fluid" />
                        </div>
                        {!isEmpty(cabList) && <div className="cab-detail-list">
                            <CabListPanel list={cabList} />
                        </div>}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Bannersec;