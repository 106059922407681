import Header from "../Header/Header";
import FooterComponent from "../sections/footer-comp/FooterComponent";
import './contactus.css';

export default function Contactus() {
    return (
        <div className="contactus-page">
            <Header />
            <div className="choose2-sec sec-padding container">
                <section id="widget_1616529550031" class="csol-header csol-header-photo -dark">
                    <div class="csol-header-photo-wrapper">
                        <div class="csol-header-content-wrapper">
                            <div class="csol-header-content">
                                <h1 class="csol-header-heading ">Get in touch</h1>
                                <div class="cl-rich-text csol-header-description -dark">Want to get in touch? We'd love to hear from you. Here's how you can reach us.</div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <FooterComponent />
        </div>
    )
}