import './policy.css'


export default function TermPage(){
    return (
        <div className="term-page">
            <div class="container-fluid mt15 n">
                <div class="row">
                    <div class="col-12 bg-black p0 text-center">
                        <img src="/images/terms.jpg?v=0.1" alt="Terms and Conditions" title="Terms and Conditions" class="img-fluid" />
                    </div>
                </div>
            </div>
            <div class="row bg-gray">
                <div class="col-12">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 mb20 mt30 ul-style-c">
                                <div class="bg-white-box">
                                    <h1 class="font-22"><b>Terms and Conditions</b> <span class="font-14 pull-right color-green2"><b>Last updated September 1, 2023</b></span></h1>
                                    <p>Thanks for using our products and services ("Services"). The Services are provided by IT Cab (hereinafter referred to as "IT Cab" or "IT Cab"), with our office located at Darbhanga, bihar, 846004.</p>
                                    <p>Please read these terms carefully. By using our services, you are agreeing to all terms &amp; conditions set forth.</p>
                                    <p>In this agreement, the words "including" and "include" mean "including, but not limited to."</p>

                                    <h2 class="font-18 mt30"><b><u>DEFINITIONS</u></b></h2>
                                    <p>IT Cab or IT Cab is a technology company that makes available a platform for matchmaking "consumers" seeking travel services from and to various destinations across India with "providers" who can provide such travel services. The services are delivered by the providers and IT Cab acts simply as marketplace platform. In this agreement, the words “we”,  “us” or “our” mean “IT Cab Cabs”.</p>
                                    <p> Services – IT Cab engages in the business of provision of tour and travel related services through it's platform. Collectively these are referred to as Services.</p>
                                    <p> Platform - IT Cab engages in the business of provision of tour and travel related services through its various platforms, including its website(s), communication systems like phone or SMS, customer service &amp; call centers, customer and vendor management systems, support ticketing systems and across its web and mobile applications. Collectively, these ways of enabling our services are referred to as the IT Cab Platform.</p>
                                    <p> Consumer, Customer or You – An individual or organization that seeks services delivered by independent third-party Operators through the IT Cab Platform. Such an individual or organization is a consumer on the IT Cab Platform. A customer seeks services either for his/her/their own use or use by any other person/client/customer of an organization. The customer therefore may or may not be the traveller (actual user of the service). In this agreement, the words “you”, “your” mean the “Consumer” or “Customer”. The terms of service however, are equally binding on the Customer and the traveler, if they are not one and the same person. If the traveler is not one and the same person as the Customer, then it is the responsibility of the Customer to apprise the traveler with the terms of service and any other information as is made available to him upon use of IT Cab platform.</p>
                                    <p> Provider, Provider Partner or Third Party Provider – An individual or organization that operates as an independent third-party and uses or interacts with the IT Cab technology platform to receive marketing leads for consumers that can avail of the provider's service or product. A provider or Provider Partner is an independent third-party who has a contractual relationship with IT Cab.</p>
                                    <h2 class="font-18 mt30"><b><u>ELECTRONIC COMMUNICATIONS</u></b></h2>
                                    <p> When you use the IT Cab Platform or send e-mails, text messages, and other communications from your desktop or mobile device to us, you may be communicating with us electronically. You consent to receive communications from us electronically, such as e-mails, texts, mobile push notices, or notices and messages on this site or through the IT Cab platform. You agree that all agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.</p>
                                    <h2 class="font-18 mt30"><b><u>USING OUR SERVICES</u></b></h2>
                                    <p>You must follow any policies made available to you within our Services.</p>
                                    <p> Using our Services does not give you ownership of any intellectual property rights in our Services or the content that you access. You may not use content from our Services unless you obtain permission from its owner or are otherwise permitted by law. These terms do not grant you the right to use any branding or logos used in our Services. Do not remove, obscure or alter any legal notices displayed in or along with our Services.</p>
                                    <p> In connection with your use of the Services, we may send you service announcements, administrative messages and other information. You may opt out of some of those communications.</p>
                                    <p> Some of our Services are available on mobile devices. Do not use such Services in a way that distracts you and prevents you from obeying traffic or safety laws.</p>
                                    <p> Some of our offered services have additional terms &amp; conditions that apply. Those terms &amp; conditions are listed separately and apply to your use of such services. If at anytime, if the terms listed between those additional terms &amp; the terms listed in this document seem to overlap, then the terms that are more specific, more restrictive in scope or more directly applicable to the use-scenario shall be applicable.</p>
                                    <h2 class="font-18 mt30"><b><u>SECURITY ADVISORIES</u></b></h2>
                                    <p>We take customer and driver safety seriously. </p>
                                    <p>Customer's pickup and drop address must be a valid residential, commercial (office, hotel, shopping mall, markets etc.) or public places (railway stations, airports, bus depots or any other known landmarks). Any isolated area where driver safety may be a concern shall not be considered as a valid pickup address. The Driver reserves the right to refuse a pickup from such location which he deems to be unsafe. The customer shall be required to select a valid and acceptable pickup point in such a case. </p>
                                    <p>We advise and encourage the customers to verify the identity of the driver before the trip begins to confirm if they are travelling with the same person as assigned by IT Cab. We also urge our customers to verify the license plate of the car and confirm if they are commercial (yellow plated) and matches the details provided by IT Cab. </p>
                                    <p>Similarly, the driver may seek customer's identification proof to ensure that he is picking the right person(s) for the trip. </p>
                                    <p>Considering safety of both Customer and Driver in mind, we usually do not allow a Driver to drive continuously for more than 6 hours at a stretch to avoid Driver fatigue and encourage change of Driver and if required, vehicle from major break points in the journey.</p>
                                    <h2 class="font-18 mt30"><b><u>OUR PLATFORM AND ITS CONTENTS</u></b></h2>
                                    <p>The contents on our platform is for your general information and use only. It is subject to change without prior notice. Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law. Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that you understand the terms listed on your booking confirmation emails, invoices and that the use of any products, services or information made available through our platform meet your specific requirements.</p>
                                    <h2 class="font-18 mt30"><b><u>YOUR IT Cab ACCOUNT</u></b></h2>
                                    <p>To protect your IT Cab Account, keep your password confidential.</p>
                                    <p>You may need a IT Cab Account in order to use our Services. This could be an individual account or an Agent/Reseller/Corporate Account. You may create your own IT Cab Account, or your IT Cab Account may be assigned to you by an administrator, such as a call center / business representative at the time of creation of a reservation by phone. If you are using a IT Cab Account assigned to you by an administrator, different or additional terms may apply, and your administrator may be able to access or disable your account.</p>
                                    <p>You are responsible for the activity that happens on or through your IT Cab Account. Try not to reuse your IT Cab Account password on third-party applications. If you learn of any unauthorised use of your password or IT Cab Account, please notify us immediately by email at info@IT Cab.com or phone number published to our website.</p>
                                    <p>IT Cab may suspend your account at any time that your account is suspected to be misused, found to be used for any unauthorized purpose or suspected to be in violation of IT Cab terms and conditions.</p>
                                    <p>IT Cab reserves the right to refuse service, terminate accounts, terminate your rights to use IT Cab Services, remove or edit content, or cancel orders in its sole discretion.</p>
                                    <h2 class="font-18 mt30 text-uppercase"><b><u>ABOUT THESE TERMS</u></b></h2>
                                    <p>We may modify these terms or any additional terms that apply to a Service to, for example, reflect changes to the law or changes to our Services. You should look at the terms regularly. We'll post notice of modifications to these terms on this page. We'll post notice of modified additional terms in the applicable Service. Changes will not apply retrospectively and will become effective the day after they are posted. However, changes addressing new functions for a Service or changes made for legal reasons will be effective immediately. If you do not agree to the modified terms for Service, you should discontinue your use of our Service.</p>
                                    <p>If there is any inconsistency between these terms and any of the additional terms, the additional terms will prevail to the extent of the inconsistency.</p>
                                    <p>These terms govern the relationship between IT Cab and you. They do not create any third party beneficiary rights.</p>
                                    <p>If you do not comply with these terms and we do not take action immediately, this doesn't mean that we are giving up any rights that we may have (such as taking action in the future).</p>
                                    <p>If it turns out that a particular term is not enforceable, this will not affect any other terms.</p>
                                    <p>The laws of India will apply to any disputes arising out of or relating to these terms or the Services. All claims arising out of or relating to these terms or the Services will be referred to an arbitrator appointed by IT Cab, failing him to any other arbitrator chosen by IT Cab and you in writing. The decision of such an arbitrator shall be binding on both parties.</p>
                                    <p>For information about how to contact IT Cab, please refer to our website.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}